.projects-other {
  position: relative;
  padding-bottom: 10vh;
  background: linear-gradient(0deg, black 40%, white 0%);
  min-height: 95vh;
}

.other-info {
    padding: 10vw;
    padding-top: 17vh;
    padding-bottom: 5vh;
}

.description-other {
  font-size: 3vh;
  text-transform: none;
  padding-top: 2%;
  padding-bottom: 0%;
}

.title-text-other {
  font-size: 7vh;
  text-transform: none;
  color: black;
}

.other-project-tile-content {
  border: 1vw solid black;
  cursor: pointer;
  margin-bottom: 2vh;
}

.other-project-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 15vw;
  object-fit: cover;
}

.project-title {
  font-size: 3vh;
  text-align: center;
  color: black;
  height: 5vh;
  background-color: white;
}

.projects-other-grid {
  width: 100%;
  display: block;
  overflow: hidden;
  padding-left: 8vw;
  padding-right: 8vw;
  padding-bottom: 1vh;
}

@media (max-width: 1200px) {
  .projects-other {
    background: linear-gradient(0deg, black 50%, white 0%);
  }
}

@media (max-width: 768px) {
  .other-projects-small {
    cursor: pointer;
    height: 20vh;
    padding:0%
  }

  .title-text-other {
    text-align: center;
    color: white;
    font-size: 6vh;
  }

  .other-info {
    padding-top: 5vh;
    padding-bottom: 1vh;
  }

  .description-other {
    font-size: 3.1vw;
    text-align: center;
    color: white;
  }

  .other-project-image {
    height: 20vh;
    border: 0;
    width: 100%;
    padding: 0;
  }

  .projects-other {
    padding: 0;
    background: black;
    color: white;
  }

  .project-title {
    height: 23vh;
    width: 100%;
    font-size: 4vw;
    text-align: center;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .projects-other-grid {
    padding-left: 0;
    padding-right: 0;
  }
}

  
  