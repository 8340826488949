.about-timeline-container {
  background: black;
  color: white;
}

#timeline-title {
  padding: 5vh 0 0 0;
}
#timeline-title > h1{
  font-size: 5vh;
}
.timeline-content{
  font-size: 2vh;
}
.timeline-element-img {
  width: 100%;
  border-radius: 3px;
}

.timeline-element-container {
  padding: 2vh;
}

@media (min-width: 769px) {
  .about-timeline-container {
    height: 95vh;
  }
}