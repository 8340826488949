.card-image-container{
      width: 100vw;
      height: 85vh;
      max-height: 85vh;
      min-height:50vh;
      display: flex;
      align-items: center;
      overflow: hidden;
}
.space{
      height:10vh;
      width: 100vw;
}

.card-image-container > img{
      width:100vw;
}

@media (max-width: 576px) {
      .card-image-container{
            min-height:auto;
      }

}
