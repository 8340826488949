.projects-content {
    background: linear-gradient( 45deg, rgba(0, 0, 0, 1) 10%, rgb(42, 46, 59) 100%) top left/auto repeat fixed;
    width: 100%;
    padding: 0;
}

.main-project-card-container {
    height: auto;
}

.projects-content .icon-container {
    width: 100%;
    height: 13vh;
    background-color: transparent;
}

.projects-content .icon-btn {
    cursor: pointer;
    color: rgb(0, 225, 250);
    font-size: 30px;
}

@media (max-width: 768px) {
    .main-project-card-container {
        min-height: 100vh;
    }
}