.candidates-join-us {
  background: url(../../../images/github_pattern.svg) top left/auto repeat fixed, #1C2329;
  text-align: center;
  padding-top: 4vh;
  padding-bottom: 4vh;
  height: 95vh
}

.candidates-join-us-title-container {
  color: #fff;
  font-size: max(3rem, 5vh) !important;
}

.candidates-join-us-title-container > p {
  margin-bottom: -0.5rem;
}

.candidates-join-us-btn-container {
  width: 100%;
  font-size: 40px;
  font-weight: bold;
  min-height: 75px;
}

.candidates-join-us-btn {
  margin: auto;
  cursor: pointer;
  border: 1px solid #4F72B8;
  border-radius: 8px !important;
  font-size: 30px;
  width: 22%;
  height: 80px;
  background-color: #13191E;
  color: white !important;
  transition: 0.3s;
}

@media (max-width: 768px) {
  .candidates-join-us {
    height: fit-content;
  }

  .candidates-join-us-btn {
    width: 40%;
    font-size: 25px;
    height: 65px;
  }
}

.candidates-join-us-btn:hover {
  border: 1px solid #13191E;
  background-color: #4F72B8;
}

