.supportUs-container {
    background-color: black;
    color: white;
    text-align: center;
    padding-bottom: 4vh;
    height: 100%;
    position: relative;
}

.type-container {
    padding-top: 3vh;
    padding-bottom: 3vh;
    padding-right: 5vw;
    padding-left: 5vw;
}

.type-container:hover {
    cursor: pointer;
}

.supportUs-title {
    font-size: 3.5vw;
    text-align: center;
    display: block;
}

.supportUs-image {
    width: 60%;
    max-width: 35vh;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.see-packages-button {
    font-size: 2.5vh;
    color: #FFDE59;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    bottom: 3vh;
    left: 0;
    right: 0;
    text-align: center;
    width: fit-content;
}

.see-packages-icon {
    color: '#FFDE59';
    font-size: 180%;
    padding: 0;
    cursor: pointer;
}

.see-packages-icon:hover {
    color: #FF7F11;
}

.supportUs-description {
    font-size: 2.5vh;
    padding-left: 4vw;
    padding-right: 4vw;
}

@media (min-width: 992px) {
    .supportUs-types {
        margin: 0;
        position: absolute;
        top: 45%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}

@media (max-width: 991px) {
    .type-container {
        height: 30vh;
    }

    .supportUs-container {
        position: static;
        height: fit-content;
        padding-right: 8vw;
        padding-left: 2vw;
    }

    .see-packages-button {
        display: none;
    }

    .supportUs-description {
        font-size: 2vh;
        padding-left: 2vw;
        padding-right: 2vw;
        margin: auto;
    }

    .supportUs-image {
        height: 17vh;
        width: 17vh;
    }

    .supportUs-title {
        font-size: 6vw;
    }
}