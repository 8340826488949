.position-card {
    max-height: 25vh;
}

.positions-card-container {
    justify-content: center;
}

@media(max-width: 992px) {
    /* .candidates-open-positions-card {
        margin-left: 15px;
        margin-right: 15px;
        height: 270px;
        max-height: 300px;
        max-width: 330px;
    } */
}