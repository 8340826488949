.scroll-next {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0;
}

.scroll-next-icon {
    font-size: 5vh;
    color: #00FFFA;
    padding-top: 0;
    height: 12vh;
}

.scroll-next-icon:hover {
    color: #2870CE;
    cursor: pointer;
  }

.projects-card {
    color: rgba(255, 255, 255, 0.8);
    height: 83vh;
    display: flex;
    align-items: center;
    padding-top: 5vh;
  }

.card-info {
    padding-left: 5vw;
    padding-right: 5vw;
    height: fit-content;
}

.card-button {
    margin: auto;
    cursor: pointer;
    border: 1px solid #4F72B8;
    border-radius: 8px !important;
    width: 15vw;
    height: 8vh;
    background-color: #13191E;
    color: white !important;
    transition: 0.3s;
    font-size: 1.5vw;
}

.card-image {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    height: 75vh;
    width: 45vw;
    object-fit: cover
}

.projects-card .main-text-projects {
    color: rgba(255, 255, 255, 0.9);
    font-size: 3vh;
    text-transform: none;
    padding-top: 2vh;
    padding-bottom: 3vh;
}

.title-text-card {
    color: rgba(255, 255, 255, 0.9);
    font-size: 7vh;
    text-transform: none;
}

@media (max-width: 1200px) {
    .projects-card .main-text-projects {
        font-size: 2vw;
    }   
}

@media (max-width: 768px) {
    .projects-card {
        min-height: 100vh;
        width: 100%;
        display: block;
        align-items: center;
    }

    .title-text-card {
        padding: 0;
        text-align: center;
        min-height: 15vh;
        padding-top: 5vh;
        padding-bottom: 0vh;
        width: 100%;
    }

    .main-text-projects-description {
        padding: 0;
        height: fit-content;
        padding-left: 6vw;
        padding-right: 6vw;
        padding-top: 2vh;
        padding-bottom: 3vh;
        text-align: center;
        font-size: 100%;
        justify-content: center;
        align-items: center;
    }

    .card-image-container {
        height: 40vh;
        width: 100%;
        padding: 0;
    }

    .card-image {
        padding: 0;
        margin: 0;
        width: 100%;
    }

    .button-div {
        height: auto;
        width: 100%;
    }

    .card-button-small {
        display: block;
        font-size: 4vw;
        width: 40vw;
        margin-left: auto;
        margin-right: auto;
        padding-left: 5vw;
        padding-right: 5vw;
        border: 1px solid #4F72B8;
        border-radius: 8px !important;
        background-color: #13191E;
        color: white !important;
        transition: 0.3s;
        cursor: pointer;
    }
}
