.send-email-form {
      background-color: #D9D9D9;
      padding: 7vh 10vw 5vh 5vw;
      height: 95vh;
}

.send-email-phrase {
      background-color: #13191E;
      height: 95vh;
      font-weight: bold;
}

.big-letters-title {
      padding: 0 10%;
      color: white;
      font-weight: bold;
      font-size: 400%;
      margin: 0;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
}

.send-email-title {
      font-size: 3vh;
      padding-bottom: 2vh;
}

.form-button-submit {
      background-color: #6A2C94;
      border: transparent;
}

.checkbox-description {
      padding-top: 1.2vh;
}

@media (max-width: 991px) {
      .big-letters-title {
            font-size: 350%;
      }
}

.grecaptcha-badge{
      z-index: 2000;
}
.formLoading{
      opacity: 0.25;
}
.loadingSymbol{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
}
.spinSymbol {
      animation: spin 1s linear infinite;
      margin-left: 0.25rem;
      margin-right: 0.75rem;
      height: 1.5rem;
      width: 1.5rem;
      color: #4F72B8;
}
@keyframes spin {
      from {
            transform: rotate(0deg);
      }
      to {
            transform: rotate(360deg);
      }
}
.opacity-25{
      opacity: 0.25;
}
.opacity-75{
      opacity: 0.75;
}


/* <label class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-outlined" data-shrink="false" for="outlined-helperText" id="outlined-helperText-label">Name</label> */


/* <div class="MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth"><label class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-outlined" data-shrink="false" for="outlined-helperText" id="outlined-helperText-label">Name</label><div class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl"><input aria-invalid="false" id="outlined-helperText" type="text" class="MuiInputBase-input MuiOutlinedInput-input" value=""><fieldset aria-hidden="true" class="PrivateNotchedOutline-root-2 MuiOutlinedInput-notchedOutline"><legend class="PrivateNotchedOutline-legendLabelled-4"><span>Name</span></legend></fieldset></div></div> */

/* <div class="MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth">
<div class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-multiline MuiOutlinedInput-multiline"><grammarly-extension data-grammarly-shadow-root="true" class="cGcvT" style="position: absolute; top: 0px; left: 0px; pointer-events: none;">
</grammarly-extension><textarea aria-invalid="false" id="outlined-multiline-static" placeholder="Your message" rows="7" class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMultiline MuiOutlinedInput-inputMultiline" spellcheck="false"
></textarea><fieldset aria-hidden="true" class="PrivateNotchedOutline-root-2 MuiOutlinedInput-notchedOutline" style="padding-left: 8px;"><legend class="PrivateNotchedOutline-legend-3" style="width: 0.01px;"><span>​</span></legend></fieldset></div></div> */



/* .MuiTextField-root {
      margin: 100%;
      background-color: teal;
} */

/* .MuiOutlinedInput-root {
      color: black;
      background-color: white;
      margin: black;
}

.MuiOutlinedInput-placeholder {
      color: black;
      background-color: red;
      margin: black;
} */

/* .MuiInputBase-fullWidth {
      background-color: cyan;
      border-color: red;
      color: red;
} */


/* <textarea aria-invalid="false" id="outlined-multiline-static" placeholder="Your message" rows="4" class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMultiline MuiOutlinedInput-inputMultiline" spellcheck="false"></textarea>


<div class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl"><input aria-invalid="false" id="outlined-helperText" type="text" class="MuiInputBase-input MuiOutlinedInput-input" value=""><fieldset aria-hidden="true" class="PrivateNotchedOutline-root-2 MuiOutlinedInput-notchedOutline"><legend class="PrivateNotchedOutline-legendLabelled-4"><span>Phone</span></legend></fieldset></div> */