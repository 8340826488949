.members-grid {
  background: rgba(20, 18, 19);
  min-height: 100%;
  width: 100%;
  transform: translate(0.2%, 0%);
}

.members-grid-container {
  background: rgba(20, 18, 19);
  width: 100%;
  padding: 1.5px;
}

.members-grid-search-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5vh 5vw 3vh 5vw;
  background: #545454;
  border-radius: 20px;
  padding: 1vh 2vw;
  color: #aaa;
}

.members-grid-search-bar-input {
  flex-grow: 1;
  background: inherit;
  border: none;
  padding-left: 1vw;
  outline: none;
  color: #eee;
  text-overflow: ellipsis;
  font-size: 3vh;
  width: 100%;
}

.members-search-bar-icon {
  font-size: 3vh;
}

.grid-title {
  position: relative;
  padding: 4% 0 2%;
  color: rgba(255, 255, 255, 0.8);
  background: rgba(20, 18, 19);
  text-align: center;
}

.grid-title-text {
  font-size: 3vw;
}

.members-grid .members-grid-tile .member-image-container .member-image {
  height: 100%;
  transition: 0.4s;
  width: 100%;
  cursor: pointer;
}

.members-grid .members-grid-tile .member-image-container {
  position: relative;
  padding: 0;
  width: 100%;
  display: block;
  cursor: pointer;
  overflow: hidden;
}

.members-grid .members-grid-tile .member-image-container .member-image-content {
  opacity: 0;
  font-size: 4vh;
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  text-align: center;
  vertical-align: middle;
  padding-top: 30%;
}

.members-grid .members-grid-tile .member-image-container .member-image-content:hover {
  opacity: 1;
}

.members-grid .members-grid-tile .member-image-container .member-image-content .text {
  height: 0;
  opacity: 1;
  transition-delay: 0s;
  transition-duration: 0.4s;
}

.members-grid .members-grid-tile .member-image-container .member-image-content:hover .text {
  opacity: 1;
  transform: translateY(250px);
  -webkit-transform: translateY(250px);
}

.members-grid .members-grid-tile .member-image-container .member-image-content .member-image-icon {
  position: absolute;
  right: 5%;
  bottom: 0;
}

.members-grid .members-grid-tile .member-image-container .member-image-content
.fa-code,
.fa-microchip,
.fa-cog,
.fa-bullhorn {
  color: 	rgba(223, 223, 223, 0.5);
}

.modal-container {
  background-color: black;
}

@media (max-width: 768px) {
  .grid-title-text {
    font-size: 3vh;
  }

  .members-grid-search-bar-input {
    padding-left: 3vw;
  }

  .members-grid-search-bar {
    padding-left: 4vw;
  }
}