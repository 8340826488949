#app-navbar {
  height: 7.5vh;
  min-height: 48px;
  padding: 0 1vh 0 0;
  font-size: 2.5vh;
}

#app-navbar .navbar-brand {
  padding-left: 1vw;
}

#app-navbar #navbar-logo {
  height: 5vh;
  padding-left: 1vw;
}

#app-navbar .navbar-toggler {
  margin-left: 2vw;
  margin-right: 2vw;
}

#app-navbar .navbar-nav .navbar-btn {
  margin-left: 1.5vw;
  height: fit-content;
  padding-bottom: 0;
}

#app-navbar .navbar-nav .navbar-btn.active {
  color: rgb(0, 225, 250);
  border-bottom: 0.4vh solid;
}

#app-navbar .navbar-nav .navbar-btn:hover .navbar-btn-legend {
  transform: translateY(0.1vh);
  color: rgb(0, 225, 250);
  cursor: pointer;
}

#app-navbar .navbar-nav .navbar-btn.active .navbar-btn-legend {
  color: rgb(0, 225, 250);
}

#app-navbar .navbar-nav .navbar-btn-legend {
  color: white;
  padding-bottom: 0;
}

#app-navbar #navbar-sponsor-logo {
  margin-top: -2vh;
  padding-left: 0.5vw;
  height: 8vh;
}

#app-navbar #navbar-legend-div {
  color: #888B8D;
  height: 100%;
  font-size: 2.5vh;
  padding-top: 1vh;
}

#app-navbar #navbar-legend-div:hover {
  cursor: pointer;
}

@media (max-width : 1024px) {
  #app-navbar {
    font-size: 1.5vh;
  }

  #app-navbar #navbar-logo {
    height: 3vh;
  }

  #app-navbar #navbar-sponsor-logo {
    margin-top: -1.3vh;
    height: 5vh;
  }

  #app-navbar #navbar-legend-div {
    font-size: 1.5vh;
    height: fit-content;
  }
}

@media (max-width : 991px) {
  #app-navbar .navbar-nav {
    font-size: 3vh;
    align-items: center;
    background-color: rgb(25, 30, 35);
    width: 100vw;
  }

  #app-navbar #navbar-logo {
    height: 4vh;
  }

  #app-navbar .navbar-nav .navbar-btn-legend {
    padding: 0 5vw;
    margin-top: 1vh;
  }

  #app-navbar #navbar-legend-div {
    padding-top: 4vh;
    width: 60vw;
  }

  #navbar-legend {
    font-size: 3vh;
  }

  #navbar-sponsor-logo {
    display: block;
    padding-top: 1vh;
    margin-left: 1vw;
    margin-top: 0vh;
    height: 55%;
    width: 55%;
    object-fit: cover;
  }
}

@media (min-width: 992px){
  #app-navbar #navbar-legend-div {
    position: absolute;
    right: 2px;
  }
}