.members-join-us {
  height: 60%;
  position: relative;
  background: linear-gradient( 45deg, rgba(0, 94, 105, 0.9) 30%, rgba(20, 18, 19, 0.9) 100%), url(../../../images/github_pattern.svg) top left/auto repeat fixed;
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
}

.members-join-us-description {
  color: rgba(255, 255, 255, 0.9);
  font-size: 4vh;
  text-align: center;
  text-transform: none;
}

.members-join-us-button {
  /* left: 40%; */
  width: 28vw;
  height: 12vh;
  transition: 0.2s;
  margin-top: 100px;
}

.members-join-us-button-contain {
  position: absolute;
  height: 20%;
  width: 20%;
  border-radius: 8px;
  text-align: center;
  vertical-align: middle;
  border: 2px solid #00FFFA;
  background-color: #13191E;
  transition: 0.2s
}

.members-join-us-button-text {
  padding-top: 3.7vh;
  color: rgba(255, 255, 255, 0.9);
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  font-size: 3vh;
  padding: 0;
  display: table-cell;
  vertical-align: middle;
}

@media only screen and (max-width : 992px){
  .members-join-us-button-contain {
    height: 20%;
    width: 40%;
  }
  /* .members-join-us-button {
    left: 36%;
  } */
}

.members-join-us-button:hover {
  cursor: pointer;
  background-color: rgb(20, 200, 220);
  border: 2px solid rgb(30, 200, 220);
}

