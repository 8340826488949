.candidates-video-container {
    background-color: #000;
    position: relative;
}

.candidates-video {
    height: 95vh;
    width: 100%;
    background-color: black;
    filter: brightness(0.80);
}

.candidates-video-caption {
    position: absolute;
    bottom: 5vh;
    left: 3vw;
    width: 30vw;
    color: white;
}

.candidates-video-caption-title {
    font-size: 3.5vw;
    font-weight: bolder;
    letter-spacing: 0.2vw;
}

.candidates-video-caption-subtitle {
    font-size: 1.5vw;
    font-weight: bolder;
    letter-spacing: 0.3vw;
}

@media (max-width: 768px) {
    .candidates-video-caption {
        top: 70px;
        bottom: auto;
        width: 60vw;
        left: 5vw;
    }

    .candidates-video-caption-title {
        font-size: 9vw;
        letter-spacing: 0.6vw;
        line-height: 1.1;
    }
    
    .candidates-video-caption-subtitle {
        padding-top: 1vh;
        font-size: 3vw;
        letter-spacing: 0.5vw;
    }
}