.contact-sponsor-us {
  background: url(../../../images/github_pattern.svg) top left/auto repeat fixed #1C2329;
  color: white;
  padding-top: 5vh;
  text-align: center;
  min-height: 95vh;
}

.package-column {
  padding: 0 3vw 3vh 0;
  height: fit-content;
}

.packages-card {
  height: 100%;
  background-color: #13191E;
  border-radius: 6px;
  padding: 0 0.7vw;
  border: none;
  background-repeat: no-repeat;
  background-image:   linear-gradient(to right, #CB6CE6 100%, #CB6CE6 100%),
                      linear-gradient(to bottom, #CB6CE6 100%, #CB6CE6 100%),
                      linear-gradient(to bottom, #CB6CE6 100%, #CB6CE6 100%),
                      linear-gradient(to right, #CB6CE6 100%, #CB6CE6 100%);
  background-size:    0% 3px,
                      3px 0%,
                      0% 3px,
                      3px 0%;
  background-position:    0 0,
                          100% 0,
                          100% 100%,
                          0 100%;
  animation: bg1 0.75s cubic-bezier(0.19, 1, 0.22, 1) 1;
}

.Background-card{
  background-color: #13191E;
  border-radius: 6px;
  border: 5px #13191E;
}

@keyframes bg {
  0% {
      background-size:    0% 3px,
                          3px 0%,
                          0% 3px,
                          3px 0;
  }
  25% {
      background-size:    100% 3px,
                          3px 0,
                          0 3px,
                          3px 0;
  }
  50% {
      background-size:    100% 3px,
                          3px 100%,
                          0 3px,
                          3px 0;
  }
  75% {
      background-size:    100% 3px,
                          3px 100%,
                          100% 3px,
                          3px 0;
  }
  100% {
      background-size:    100% 3px,
                          3px 100%,
                          100% 3px,
                          3px 100%;
  }
}

@keyframes bg1 {
  0% {
    background-size:    100% 3px,
                        3px 100%,
                        100% 3px,
                        3px 100%;
  }
  50% {
      background-size:    100% 3px,
                          3px 0,
                          100% 3px,
                          3px 0;
  }
  100% {
      background-size:    0 3px,
                          3px 0,
                          0 3px,
                          3px 0;
  }
}
.Background-card:hover{
  border: transparent;
}
.packages-card:hover {
  background-image:   linear-gradient(to right, #CB6CE6 100%, #CB6CE6 100%),
                      linear-gradient(to bottom, #CB6CE6 100%, #CB6CE6 100%),
                      linear-gradient(to right, #CB6CE6 100%, #CB6CE6 100%),
                      linear-gradient(to bottom, #CB6CE6 100%, #CB6CE6 100%);
  background-size:    100% 3px,
                      3px 100%,
                      100% 3px,
                      3px 100%;
  background-position:    0 0,
                          100% 0,
                          100% 100%,
                          0 100%;
  animation: bg 0.5s cubic-bezier(0.19, 1, 0.22, 1) 1;
}

hr {
  border-color: white !important;
}

.package-title {
  font-size: 2vw;
}

.benefit-container {
  display: flex;
  flex-direction: row;
  text-align: left;
  padding-top: 1vh;
  width: 100%;
}

.benefit-icon {
  color: #CB6CE6;
  font-size: 1.5vw;
  padding-right: 1vw;
}

.benefit-text {
  font-size: 1vw;
}

.package-card-footer {
  display: flex;
  position: absolute;
  bottom: 1px;
  left: 0%;
  padding: 0 1vw 2vh 1vw;
  width: 100%;
}

.package-card-footer img {
  height: 6vh;
}

.go-send-email-button {
  padding: 0 3vw 5vh 0;
  font-size: 2.3vh;
}

.go-send-email-icon {
  font-size: 5vh;
  color: #CB6CE6;
}

.go-send-email-icon:hover {
  color: #FFDE59;
  cursor: pointer;
}

@media (min-width: 992px) {
  .benefit-container {
    margin-bottom: 0;
  }

  .package-column {
    padding-bottom: 0;
    height: 72vh;
  }
}

@media (max-width: 991px) {
  .package-card-footer {
    position: relative;
  }

  .package-title {
    font-size: 3vh;
  }

  .package-card-footer img {
    width: 10vw;
    height: auto;
  }
}