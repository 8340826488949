.home-header {
  width: 100%;
  height: 100%;
  background: linear-gradient( 90deg, rgba(0, 0, 0, 0.5) 40%, rgba(0, 0, 0, 0.5) 100%), url(../../../images/home/team.jpg);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  color: #fff;
}

.home-header .row {
  height: 100%;
}

.header-img-col {
  height: 100%;
  padding-top: 30vh;
  display: block;
  margin: auto;
  align-items: center;
  text-align: center;
}

.header-img {
  width: 50vw;
}

.react-rotating-text-cursor {
  animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s infinite;
}

@keyframes blinking-cursor {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
