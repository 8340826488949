#horizontal-timeline{
      position:relative;
      float:left;
}
.myCard {
      background-color: transparent  !important;
      width: 90%  !important;
      transition: 0.2s !important;
      margin: 10% 5%  !important;
      position: relative !important;
}
.my-info{
      position: absolute ;
      display: flex;
      bottom: 0;
      width: 100%;
      height: 15%;
      color: white;
      background-color: #FF7549;
      opacity: 0.9;
      text-align: center;
      font-size: 1.2rem;
      align-items: center;
      justify-content: center;
}
.my-dots{
      margin:15px 0;
}
.myCard:hover {
      transform: rotate(-12);
      transform: fade(0.5);
      transform: darken (0.2);
      box-shadow: 0 3px 5px 0;
      transform: scale(1.03,1.03);
      color: rgba(211, 211, 211, 0.336);
}

.slick-prev:before, .slick-next:before { font-family: "slick"; font-size: 40px; line-height: 1; color: white; opacity: 0.75; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }   

.slick-prev:before { content: "‹"; }
[dir="rtl"] .slick-prev:before { content: "›"; }

[dir="rtl"] .slick-next { left: -10px; top: 70px; right: auto; }
.slick-next:before { content: "›"; }
[dir="rtl"] .slick-next:before { content: "‹"; }

.timeline {
      margin: auto;
      height: 1px;
      background-color: white;
      width: 80%;
}

.timeline-progress {
      width: 0;
      height: 100%;
      background-color: #FF7549;
}

.timeline-items {
      margin-left: -10px;
      margin-right: -10px;
      margin-top: -9px;
      display: flex;
      justify-content: space-between;
}

.timeline-item {
      position: relative;
      cursor: pointer;
}

.timeline-item::before {
      content: '';
      width: 17px;
      height: 17px;
      background-color: white;
      display: block;
      border-radius: 100%;
}
.my-info{
      font-size: 2.5vh;
}
#event-content >h3{
      font-size: 3vh;
}
#event-content >h3 + p{
      font-size: 2vh;
}
.timeline-content {
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
      background-color: transparent;
      width: 100px;
      padding: 5px 10px;
      border-radius: 5px;
      text-align: center;
      font-size: 0.9rem;
}

.timeline-item.active::before {
      background-color: #FF7549;
}

#event-content {
      opacity: 0;
      font-size: 1rem;
      position: absolute;
      top: 0;
      left: 0;
      color: white;
      background-color: #FF7549;
      width: 100%;
      height: 100%;
      border-radius: 3px;
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
      -o-transition: all 0.3s ease-out;
      -ms-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out;
      text-align: center;
}

.myCard:hover #event-content {
opacity: 0.95;

}
.myCard:hover  .my-info{
      opacity: 0;
}