button:focus {
  outline: none;
}

.candidates-carousel {
  background: black !important;
  height: calc(100vh - 48px);
}

.candidates-carousel-slide {
  height: 100vh;
}

.candidates-header-img-container {
  position: absolute;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.candidates-header-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.65;
}

.candidates-learn-height {
  height: calc(50vh - 28px);
  max-height: calc(50vh - 28px);
}

.candidates-succeed-height {
  height: calc(31vh - 28px);
  max-height: calc(31vh - 28px);
}

.candidates-succeed-sm-height {
  height: 200px;
  max-height: 200px;
}

.candidates-succeed-long-height {
  height: calc(69vh - 28px);
  max-height: calc(69vh - 28px);
}

.grid-background {
  background-color: #000;
  height: 100%;
}

.Carousel-text-titles {
  position: absolute;
  width: 100%;
  text-align: center;
  color: white;
  top: calc((100% - 48px)/2 - 20px + 24px + 2vw);
  font-weight: bold;
  font-size: 5vw;
  line-height: 4vw;
  z-index: 1;
  pointer-events: none;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .candidates-carousel {
    min-height: 100%;
    height: 100%;
  }

  .candidates-carousel-slide {
    height: 100%;
  }

  .Carousel-text-titles {
    top: calc((100% - 48px)/2 - 20px + 24px + 5vw);
    font-size: 15vw;
    margin-bottom: 0;
  }

  .candidates-learn-sm-height {
    height: calc(25vh - 16px);
  }

  .candidates-succeed-sm-height {
    height: calc(25vh - 18px);
  }

  .candidates-learn-height {
    height: calc(50vh - 16px);
  }
}