.why-are-we-roborregos {
  position: relative;
  height: 95vh;


}

.roborregos-video {
  height: 95vh;
  width: 100%;
  background-color: black;
  filter: brightness(0.80);
}

.roborregos-video-caption {
  position: absolute;
  bottom: 5vh;
  left: 3vw;
  width: 30vw;
  color: white;
}

.roborregos-video-caption-title {
  font-size: 3.5vw;
  font-weight: bolder;
  letter-spacing: 0.2vw;
}

@media (max-width: 768px) {
  .candidates-video-caption {
      top: 70px;
      bottom: auto;
      width: 60vw;
      left: 5vw;
  }

  .candidates-video-caption-title {
      font-size: 9vw;
      letter-spacing: 0.6vw;
      line-height: 1.1;
  }
  
}