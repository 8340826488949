.carousel{
    width:100%;
    height: 100%;
    background-color: black;
    display: flex;

}

.leftcarouselInner{
    height:100%;
    flex: 40%;
    background-color: black;
    display: flex;
    background-repeat: no-repeat;
    background-size: cover;

}
.rightcarouselInner{
    height:100%;
    flex: 60%;
    background-position: center;
    display: flex;
    background-repeat: no-repeat;
    background-size: cover;

}


.leftleft{
    height:100%;
    flex: 12.5%;
    background-color: black;
    display: grid;
    place-items: center;
    color:white;
    cursor:pointer;
}
.leftright{
    height:100%;
    flex: 75%;
    background-color: black;
    display: grid;
    color:white;
    position: relative;
}
.text-container{
    
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    
}
.text-container>h1{
    font-size: 3vw;
    
    
}
.text-container>p{
    font-size: 1.75vw;

}


.left3{
    height:100%;
    flex: 12.5%;
    background-color: black;

}
.rightleft{
    height:100%;
    flex: 90%;
    background-position: center;
}
.rightright{
    height:100%;
    flex: 8.333%;
    display: grid;
    place-items: center;
    color:white;
    cursor:pointer;
}

.smallcarousel{
    width:100%;
    height: 100%;
    background-color: black;
    display: flex;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.left{
    height:100%;
    flex: 12.5%;
    display: grid;
    place-items: center;
    color:white;
    cursor:pointer;
    background-color: rgb(0,0,0,.6);
}
.center{
    height:100%;
    flex: 75%;
    display: grid;
    color:white;
    position: relative;
    background-color: rgb(0,0,0,.6);
}

.right{
    height:100%;
    flex: 12.5%;
    display: grid;
    place-items: center;
    color:white;
    cursor:pointer;
    background-color: rgb(0,0,0,.6);
}
.SmallText-Container{
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.SmallText-Container>h1{
    font-size: 5vh;
    text-align: center;
    
    
}
.SmallText-Container>p{
    font-size: 2.75vh;
    text-align: center;

}