.members-values {
  color: rgba(255, 255, 255, 0.8);
  background: rgb(20, 18, 19);
  text-align: center;
  height: 95vh;
}

.members-values-container {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.members-values-card {
  flex: 1;
  padding: 20px 5.5vw 20px 5.5vw;
  position: relative;
  background-position: 0% 30%;
}

.members-values-card-overlay {
  opacity: 1;
  background: linear-gradient(-45deg, rgba(0,0,0,0.7), rgba(0,0,0,0));
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  transition: opacity 0.2s ease-in;
}

.members-values-card-title {
  font-size: 3vw;
  z-index: 3;
  opacity: 0;
  transition: opacity 0.6s ease-out;
  padding: 5vh 0;
}

.members-values-card:hover > .members-values-card-title {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.members-values-card-text {
  text-align: start;
  font-size: 1.3vw;
  opacity: 0;
  transition: opacity 0.6s ease-out;
  z-index: 3;
}

.members-values-card:hover > .members-values-card-text {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.members-values-card:hover > .members-values-card-overlay {
  opacity: 0%;
  transition: opacity 0.3s ease-out;
}

.members-values-card:nth-child(1) {
  background-image: url('../../../images/members/values/1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.members-values-card:nth-child(2) {
  background-image: url('../../../images/members/values/2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.members-values-card:nth-child(3) {
  background-image: url('../../../images/members/values/3.jpg') ;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 768px) {
  .members-values-container {
    flex-direction: column;
  }

  .members-values-card-title {
    font-size: 6vw;
    opacity: 1;
    padding-bottom: 0;
  }

  .members-values-card {
    padding: 3vh 10vw;
  }

  .members-values-card:nth-child(odd) {
    text-align: start;
  }

  .members-values-card:nth-child(even) {
    text-align: end;
    display: flex;
    flex-direction: inherit;
    align-items: flex-end;
  }

  .members-values-card-text {
    text-align: inherit;
    word-wrap: break-word;
    margin: 0;
    opacity: 1;
    font-size: 4vw;
  }

  .members-values-card-overlay {
    display: none;
  }
}