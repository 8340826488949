.social-media {
    height: 95vh;
    width: 100%;
    background: linear-gradient(65deg, rgba(0,0,0,1) 0%, rgba(34,34,34,1) 53%, rgba(61,64,89,1) 100%);
    color: white;
    text-align: center;
}

.caption{
    font-size: 3vw;
}

.message-us {
    padding-top: 10vh;
    font-size: 4vw;
}

.social-media-logo {
    width: 30vh;
}

.social-media-logo:hover {
    cursor: pointer;
}

.roborregos-logo {
    width: 37vh;
}

@media (max-width: 1280px) {
    .social-media-logo {
        width: 30vw;
    }

    .roborregos-logo {
        width: 30vw;
    }
}

@media (max-width: 768px) {
    .message-us {
        font-size: 10vw;
        padding-top: 3vh;
    }
    
    .caption{
        font-size: 3vh;
    }
    
    .social-media-logo {
        height: 20vh;
        width: auto;
        padding-bottom: 2vh;
    }

    .roborregos-logo {
        height: 20vh;
        width: auto;
        padding-bottom: 2vh;
    }
}