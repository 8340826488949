  .main-container {
    height: 100%;
    position: relative;
    color: rgba(255, 255, 255, 0.8);
  }
  
  .main-container .main-text {
    color: rgba(255, 255, 255, 0.9);
    font-size: 3vh;
    text-transform: none;
    padding-top: 2%;
    padding-bottom: 0%;
  }

  .main-container .container-legend {
    position: absolute;
    top: 50%;
    left: 15%;
    right: 15%;
    transform: translate(0%, -50%);
    font-size: 5vw;
  }
  
  .title-text-banner {
    color: rgba(255, 255, 255, 0.9);
    font-size: 7vh;
    text-transform: none;
  }
  
  .main-container .footer {
    font-size: 2.5vh;
    text-transform: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: absolute;
    bottom: 5vh;
    left: 0;
    right: 0;
    margin-left:auto;
    margin-right:auto;
  }
  
  .main-container .footer p {
    margin: 0;
  }
  
  .main-container .footer .icon-btn {
    cursor: pointer;
    font-size: 30px;
  }
  
  .additional-button{
    cursor: pointer;
    border-radius: 8px;
    color: white;
    transition: 0.3s;
    font-size: 3vh;
    height: 8vh;
    padding-left: 5%;
    padding-right: 5%;
}

  .additional-button:hover {
    transform: scale(1.05);
}