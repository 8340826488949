.home-sponsors-container {
  padding: 3vh 0;
}

.single-sponsor .sponsor-image {
  max-height: 15vh;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.single-sponsor .sponsor-image-lg {
  max-height: 20vh;
  max-width: 30vh;
  height: auto;
  width: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.single-sponsor .sponsor-image-md {
  max-height: 15vh;
  max-width: 15vh;
  height: auto;
  width: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.center-items {
  display: flex;
  align-items: center;
}

.single-sponsor .img-filter {
  opacity: 0;
  background: rgba(250, 250, 250, 0.4);
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  transition: 0.4s;
}

.single-sponsor:hover .img-filter {
  opacity: 0.7;
}

@media (max-width: 1024px){

  .single-sponsor .sponsor-image {
    max-height: 6vh;
  }
  .single-sponsor .sponsor-image-lg {
    max-height: 30vh;
    max-width: 30vh;
    height: auto;
    width: auto;
    content: center;
  }
  .single-sponsor .sponsor-image-md {
    max-height: 20vh;
    max-width: 20vh;
    height: auto;
    width: auto;
    content: center;
  }
}

@media (max-width: 768px){

  /* .single-sponsor .sponsor-image {
    max-height: 10vh;
  } */

  .single-sponsor .sponsor-image-lg {
    max-height: 40vh;
    max-width: 40vh;
    height: auto;
    width: auto;
  }

  .single-sponsor .sponsor-image-md {
    max-height: 25vh;
    max-width: 25vh;
    height: auto;
    width: auto;
  }
}
