.button-sticky-language {
    position: fixed;
    bottom: 6vh;
    right: 3vw;
    color: white;
    font-size: 1.5vw;
    background-color: transparent;
    border: none;
    outline:none;
    z-index:1000;
    text-shadow: 1px 1px 4px black;
    /* text-shadow: 2px 0 4px black, -2px 0 4px black, 0 2px 4px black, 0 -2px 4px black, 1px 1px black, -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black; */
}

.button-sticky-language:hover {
    cursor: pointer;
    font-weight: bold;
}

.button-sticky-language:focus {
    border: none;
    outline:none;
}

@media (max-width: 991px) {
    .button-sticky-language {
        font-size: 3vw;
    }
}