.footer-row {
  color: rgb(250, 250, 250);
  background-color:  rgb(10, 15, 20);
  z-index:1002;
  position: relative;
}

.col-logo {
  padding: 0;
}

.footer-logo {
  width: 35vh;
  object-fit: cover;
  object-position: top;
}

.sitemap-container {
  background-color:  rgb(10, 15, 20);
  padding-left: 0;
  padding-right: 0;
}

.sitemap-link {
  display: inline-block;
  padding-left: 1.5vw;
  padding-top: 24px;
  color: rgb(250, 250, 250);
  font-size: 15px;
}

.sitemap-link:hover {
  text-decoration: none;
  color: rgb(0, 225, 250);
}

.left-panel {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.goback-button {
  padding: 24px 12px 10px 0px;
}

.goback-text {
  font-size: 15px;
}

.row-socialMedia {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 10px;
}

.icon-link {
  color: rgb(250, 250, 250);
  margin: 0 5px;
}

.icon-link:hover {
  color: rgb(0, 225, 250);
}

.mark-text {
  text-align: right;
  padding-bottom: 10px;
  padding-right: 10px;
}

@media (max-width: 992px) {
  .footer-logo {
    object-fit: cover;
    height: 100%;
    object-position: 100% 30%;
  }

  .sitemap-container {
    text-align: right;
  }

  .sitemap-link {
    padding: 0;
    font-size: 15px;
    min-width: 51%;
  }

  .left-panel {
    justify-content: flex-start;
  }

  .goback-button {
    padding: 10px 0;
  }

  .goback-button a {
    padding-right: 3vw;
  }

  .goback-text {
    font-size: 15px;
  }

  .row-socialMedia {
    margin: 5px -10px 0 0;
    padding-bottom: 5px;
    background-color: rgb(10, 15, 20);
    
  }

  .mark-text {
    font-size: 15px;
    padding-right: 0px;
    padding-bottom: 0;
    color: #545454;
  }
}

@media (max-width: 430px) {
  .footer-logo {
    width: 100%;
    height: 100%;
  }

  .icon-link {
    width: max(3vh, 34px);
    max-width: 40px;
  }

  .row-socialMedia {
    margin-top: 0px;
  }
}