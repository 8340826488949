.card-info-body {
  margin-left: -10px;
  opacity: 0;
}

.candidates-card-container {
  position: relative;
  height: 100%;
}

.candidate-card-content {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
}

.candidates-open-positions-card {
  cursor: pointer;
  flex-direction: row !important;
  color: black;
  background-color: #13191E;
  border-radius: 20px;
  border: none;
  margin: 0 3vw 3vh 3vw;
  width: 35vw;
  height: 35vh;
  transition: all .3s ease-in, all .3s ease-out;
}

.candidates-open-positions-card:hover {
  cursor: pointer;
  -webkit-box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5) !important;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5) !important;
}

.candidates-card-img {
  border-radius: 15px;
  object-fit: cover;
}

.candidates-card-text {
  top: 15%;
  color: white;
  font-size: 1.5vw;
  text-align: left;
}

.candidates-card-title {
  color: white;
  font-size: 3vw;
  text-align: left;
}

.candidates-card-column-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-container {
  background: #4F72B8;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-container > svg {
  color: #13191E;
}

@media (max-width: 400px) {
  .candidates-card-image-container {
    position: absolute;
    left: -10px;
    border-radius: 50%;
    width: 57px;
    height: 57px;
    margin-right: 100px;
  }
}

.candidates-open-positions-card {
  float: left;
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: default;
}

.candidates-open-positions-card .candidates-card-column-text-container-overlay {
  height: 100%;
  padding-right: 50px;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 30%;
  opacity: 0;
  background-color: transparent;
  -webkit-transition: all .3s ease-in, all .3s ease-out;
  transition: all .3s ease-in, all .3s ease-out;
}

.candidates-card-column-text-container-overlay-original {
  opacity: 1;
  -webkit-transition: all .3s ease-in, all .3s ease-out;
  transition: all .3s ease-in, all .3s ease-out;
}



.card-info-body:hover {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.6);
}

@media (max-width: 768px) {
  .candidates-open-positions-card {
    width: 80%;
    margin-right: 0;
  }

  .candidates-card-title {
    font-size: min(35px, 9vw);
    text-align: left;
    padding: 0 3vw;
  }

  .candidates-card-text {
    font-size: 3vh;
    text-align: left;
    padding: 0 2vw;
    line-height: 4vh;
  }

  .card-info-body {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.6);
  }
}

@media (max-width: 300px) {
  .candidates-card-text {
    font-size: 2.5vh;
  }
}
