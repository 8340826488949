.home-mision-vision-container{
      width: 100%;
      height: 100%;
      position: relative;
      background-color: #191E23;
}

.home-mision, .home-vision{
      position: absolute;
      height: 100%;
      width: 67%; 
      overflow: hidden;
      background-size: cover;
}

.home-mision {
      left: 0;
      top: 0;
      background-image: url(../../../images/home/mision-vision/mision.jpg);
      -webkit-clip-path: polygon(0 0, 100% 0%, 50% 100%, 0% 100%);
      clip-path: polygon(0 0, 100% 0%, 50% 100%, 0% 100%); 
}

.home-vision{
      right: 0;
      top: 0;
      background-image: url(../../../images/home/mision-vision/vision.jpeg);
      -webkit-clip-path: polygon(50% 0, 100% 0%, 100% 100%, 0% 100%);
      clip-path: polygon(50% 0, 100% 0%, 100% 100%, 0% 100%); 
}

.mision-overlay, .vision-overlay{
      position: relative;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      opacity: 1;
      transition: .5s ease;
      color:white;
}

.mision-overlay {
      background-color: #000000c0;
}

.vision-overlay {
      background-color: #005f69c0;
}

.mision-overlay:hover,.vision-overlay:hover  {
      opacity: 0;
}

.text-mision, .text-vision{
      padding: 10%;
      position:absolute;
      font-size: 1.25rem; 
      height: auto; 
}

.text-vision{                
      text-align: right;
}

@media (min-width: 577px) {
      .text-vision{                
            bottom:0;                          
            left:0;
      }
}

@media (max-width: 576px) {

      .mision-overlay, .vision-overlay{
            display: flex;
            align-items: center;
      }

      .home-mision, .home-vision{
            height: 50%;
            width: 100%; 
            display: flex;
            justify-content: center;
            align-items: center;
      }
      
      .home-mision {
            -webkit-clip-path: none;
            clip-path: none; 
      }
      
      .home-vision{
            top: 50%;
            -webkit-clip-path: none;
            clip-path: none; 
      }
      
      .text-mision, .text-vision{
            font-size: 1.05rem;
            padding: 0% 8% 0% 8%;
      }
      
      .mision-overlay:hover,.vision-overlay:hover  {
            opacity: 1;
      }
}