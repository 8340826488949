.contact-competitions-container {
  justify-content: center;
  padding-bottom: 50px;
  padding-top: 50px;
  background: url(../../../images/github_pattern.svg) top left/auto repeat fixed #1C2329;
}

.contact-competitions-title {
  padding: 20px 4vw 40px 4vw;
  justify-content: center;
  text-align: center;
  font-size: 23px;
  color: white;
}

.competitions-img > img  {
  border-radius: 50%;
  width: 10vw;
  height: 10vw;
  -webkit-box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.75);
}

.competitions-text {
  padding: 15px 0 20px 0;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  color: white;
}

@media (max-width: 992px) {
  .competitions-img > img  {
    width: 20vw;
    height: 20vw;
  }
}
