.row {
  margin: 0;
  padding: 0;
}

.modal {
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
}

.member-modal-container {
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  background-color: #000;
}

.member-modal-container .container-helper {
  background:
    linear-gradient(
      90deg,
      rgba(19, 25, 30, 0.9) 40%,
      rgba(10, 15, 20, 0.9) 100%
    ),
    url(../../../../images/github_pattern_white.svg) top left repeat fixed;
}

.main-modal-row {
  height: 100%;
}

.image-col {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 7vw;
}

.image-cropper {
  width: 22vw;
  height: 22vw;
  overflow: hidden;
  border-radius: 50%;
  -webkit-box-shadow: 6px 6px 15px 3px rgba(0,0,0,0.75);
  -moz-box-shadow: 6px 6px 15px 3px rgba(0,0,0,0.75);
  box-shadow: 6px 6px 15px 3px rgba(0,0,0,0.75);
}

.modal-member-image {
  height: 100%;
  transform: translateX(-11%);
}

.main-modal-row .information-col {
  background: rgb(10, 15, 20);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 5vw;
  -webkit-box-shadow: 0px 0px 20px 2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 20px 2px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 20px 2px rgba(0,0,0,0.75);
}

.main-modal-row .information-col .information-container {
  padding: 0;
  width: 100%;
  height: auto;
}

.main-modal-row .information-col h2 {
  color: rgb(20, 183, 191);
}

.main-modal-row .information-col p {
  color: rgba(255, 255, 255, 0.9);
  margin: 0;
}

.main-modal-row .information-col p strong {
  font-size: 20px;
}

.main-modal-row .information-col .closing-btn {
  color: rgba(255, 255, 255, 0.9);
  font-size: 18px;
}

.main-modal-row .information-col .member-modal-btn {
  color: rgba(255, 255, 255, 0.9);
  font-size: 18px;
  text-transform: none;
  text-align: left;
}

.member-username {
  margin-left: 8px;
}

.member-titles {
  width: 100%;
  text-align: center;
  padding-bottom: 5vh;
  color: rgba(255, 255, 255, 0.9);
}

.member-subtitles {
  padding-top: 2vh;
}

.member-data {
  width: 100%;
  max-height: 40vh;
  padding-bottom: 5vh;
  font-size: 1.1vw;
}

.data-buttons {
  text-align: left;
}

.description-small {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  -webkit-box-shadow: 0px 0px 20px 2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 20px 2px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 20px 2px rgba(0,0,0,0.75);
}

.icon-small {
  display: block;
}

@media (min-width: 993px) {
  .main-modal-row .information-col {
    width: 100vw;
    height: 100vh;
  }

  .member-modal-container .container-helper {
    width: 100vw;
    height: 100vh;
  }
}

@media (max-width: 992px) {
  .image-col-small {
    padding: 0;
    display: block;
    width: 100%;
    height: 40vh;
  }

  .image-cropper {
    width: 35vh;
    height: 35vh;
    margin-left: auto;
    margin-right: auto;
  }

  .description-small {
    color: rgba(255, 255, 255, 0.9);
    background: rgb(10, 15, 20);
    padding: 0 7%;
    min-height: 57vh;
    width: 100vw;
  }

  .member-titles {
    padding-bottom: 2vh;
  }

  .name-small {
    font-size: 6vw;
    color: rgb(20, 183, 191);
  }

  .member-data {
    font-size: 100%;
    padding: 0;
    max-height: 200vh;
  }

  .close-button {
    width: 100vw;
    height: 2vh;
    padding-left: 78vw;
    padding-top: 3vh;
  }

  .data-buttons {
    padding-top: 3vh;
  }

  .main-modal-row .icon-small {
    color: rgba(255, 255, 255, 0.9);
    font-size: 18px;
    text-transform: none;
    padding: 0;
    margin-bottom: 1vh;
  }
}

@media (max-width: 414px) {
  .member-data {
    font-size: 3.5vw;
    padding: 0;
  }

  .image-col {
    padding-left: 12vw;
  }

  .description-small {
    font-size: 2vh;
  }

  .main-modal-row .icon-small {
    font-size: 12px;
  }
}