#home-mini-information-container {
  background: #000;
  text-align: center;
}

#background-filter {
  background:
    linear-gradient(
      90deg,
      rgba(33, 37, 41, 0.9) 0%,
      rgba(24, 31, 28, 0.9) 100%),
    url(../../../images/github_pattern_white.svg) top left/auto repeat fixed;
  color: rgb(250, 250, 250);
}

.col-icon {
  padding: 5% 0;
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
}

#home-mini-information-container .icon-container {
  background: #fff;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  color: rgb(24, 31, 28);
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-legend {
  margin-top: 15px;
  margin-bottom: 0;
}
