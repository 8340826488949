.timeline-element-img-container .timeline-element-img {
  height: 100%;
  transition: 0.3s;
  width: 100%;
}

.timeline-element-img-container .timeline-element-img-title {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  font-size: 2rem;
  padding: 0 6vw;
  display: -webkit-flexbox;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.timeline-element-content {
  opacity: 0;
  font-size: 4vh;
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  background: rgba(0, 0, 0);
  width: 100%;
  height: 100%;
  border-radius: 3px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  text-align: left;
  padding: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.timeline-element-content:hover {
  opacity: 1;
}

.event-title {
  font-size: 3.5vh;
}
