.video-containers {
    height: 95vh;
    width: 100%;
    background-color: black;
    padding: 5vh 5vw;
    color: white;
}

.classes-main-row {
    height: 100%;
}

.classes-section-alignment {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.central-playlist {
    width: 100%;
    height: 50%;
    border-radius: 20px;
    border: 0.3vw solid #E84B77;
    margin-right: 1vw;
}

.secondary-playlist {
    width: 100%;
    height: 25vh;
    border-radius: 20px;
    border: 0.3vw solid #E84B77;
    margin-top: 2vh;
    margin-right: 2vw;
}

.learn-with-us {
    font-size: 3vw;
}

.youtube-logo {
    width: 70%;
    padding: 2.5vh 0;
}

.caption-phrase {
    font-size: 2vw;
    padding: 0 3vw;
}

.caption-phrase-small {
    font-size: 0;
}

@media (max-width: 959px) {
    .video-containers {
        height: fit-content;
        padding: 10vh 6vw;
        color: white;
    }

    .learn-with-us {
        font-size: 9vw;
    }

    .caption-phrase {
        font-size: 0;
        padding: 0 0;
    }

    .classes-section-alignment {
        width: 100%;
    }

    .central-playlist {
        width: 100%;
        height: 30vh;
        border: 1vw solid #E84B77;
    }

    .secondary-playlist {
        width: 100%;
        height: 15vh;
        border: 1vw solid #E84B77;
    }
    
    .caption-phrase-small {
        font-size: 2vw;
        text-align: center;
    }

    .caption-phrase-small {
        padding: 3vh 10vw 0 10vw;
        font-size: 5.5vw;
    }
  }